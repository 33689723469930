import React from 'react';
import { Link, graphql } from 'gatsby';

import {
    IntroHolder,
    TwoColumns,
    Sidebar,
    Reference,
    Content,
    IntroQuote,
} from '@bahaide/shared/components/InnerPage';

import InnerPage from '../../../../components/InnerPage';

const DieGemeinschaft = ({ data }) => (
    <InnerPage title="Die Gemeinschaft" description="">
        <IntroHolder
            title="Die Gemeinschaft"
            subtitle="Die Gemeinschaft ein Ort des Fortschritts"
            picture={data.introImage}
            breadcrumbs={{
                grandparent: 'Woran Bahá’í glauben',
                parent: 'Der Einzelne und die Gesellschaft',
                'current-item': 'Die Gemeinschaft',
            }}
        >
            <IntroQuote href="/woran-bahai-glauben/der-einzelne-und-die-gesellschaft/die-gemeinschaft/zitate">
                Was die Menschen am nötigsten brauchen, sind Zusammenarbeit und
                gegenseitige Hilfe.
            </IntroQuote>
        </IntroHolder>

        <TwoColumns>
            <Content>
                <p>
                    Menschen sind Gemeinschaftswesen, die als solche nicht
                    alleine leben wollen. Zusammenarbeit mit anderen ist
                    entscheidend für unser Wohlbefinden und unsere
                    Weiterentwicklung.
                </p>
                <div>
                    <p>
                        Gemeinschaften können natürlich durch vielerlei
                        Beziehungen entstehen, zum Beispiel als Bewohner eines
                        Dorfes oder einer Nachbarschaft, als Familie, als
                        Gläubige einer Religion, als Beteiligte an Vereinen,
                        über ehrenamtliches Engagement oder über das
                        Arbeitsleben.
                    </p>
                </div>
                <div>
                    <p>
                        Jede Gemeinschaft wird dadurch geprägt, dass ihre
                        Mitglieder ein gemeinsames Ziel verfolgen. Wenn das Ziel
                        einer Gemeinschaft im Wohlergehen der Gesellschaft
                        liegt, können die Kräfte des Einzelnen durch{' '}
                        <Link to="/gemeinsames-handeln/">geeintes Handeln</Link>{' '}
                        und eine gemeinsame Vision vervielfacht werden.
                    </p>
                </div>
                <div>
                    <p>
                        <img
                            alt=""
                            src={data.dieGemeinde.childImageSharp.fluid.src}
                            className="aligncenter"
                        />
                    </p>
                </div>
                <div>
                    <p>
                        „Was die Menschen am nötigsten brauchen, sind
                        Zusammenarbeit und gegenseitige Hilfe“, sagte
                        ‘Abdu’l-Bahá. „Je stärker die Bande der Gemeinschaft und
                        Solidarität unter den Menschen sind, desto größer wird
                        die Kraft des Aufbaus und der Vollendung auf allen
                        Ebenen menschlichen Handelns.“<sup>2</sup> So wie der
                        Mensch mehr als die Summe der einzelnen Zellen ist, die
                        seinen Körper ausmachen, so übertreffen auch die Kräfte
                        einer geeinten Gemeinschaft bei weitem die gebündelten
                        Kräfte ihrer einzelnen Mitglieder.
                    </p>
                </div>
                <div>
                    <p>
                        Bahá’í versuchen in ihrer Gemeinschaft, sich diesem Ziel
                        schrittweise zu nähern. Auch in{' '}
                        <Link to="/gemeinsames-handeln/die-bahai-in-deutschland/">
                            Deutschland
                        </Link>{' '}
                        sind Bahá’í gemeinsam mit ihren Freunden bestrebt, die
                        Lehren{' '}
                        <Link to="/woran-bahai-glauben/bahaullah-und-sein-anspruch/bahaullah/">
                            Bahá’u’lláhs
                        </Link>{' '}
                        auf den materiellen und geistigen Fortschritt in der
                        Gesellschaft anzuwenden. Durch{' '}
                        <Link to="/gemeinsames-handeln/gemeinsames-lernen/">
                            gemeinsames Lernen
                        </Link>{' '}
                        können Menschen unterschiedlicher Herkunft bei der
                        Weiterentwicklung der Gemeinschaft und der sie
                        umgebenden Gesellschaft mitwirken. Jeder ist willkommen,
                        sich an diesem Prozess der Stärkung der Gemeinschaft zu
                        beteiligen. Es ist erstrebenswert, dass immer mehr
                        Menschen dazu befähigt werden, die Verantwortung für
                        ihre geistige, soziale und intellektuelle Entwicklung
                        selbst in die Hand zu nehmen. Dadurch lernt der
                        Einzelne, seinen Blick sowohl auf den eigenen
                        Fortschritt als auch auf das Wohl der Gemeinschaft zu
                        lenken. Der Weg hierzu wird in den Schriften
                        Bahá’u’lláhs als Vereinigung von{' '}
                        <Link to="/gemeinsames-handeln/andacht-und-dienst/">
                            Andacht und Dienst
                        </Link>{' '}
                        beschrieben. Dadurch werden Kräfte freigesetzt, die dem
                        Dienst an der Gemeinschaft und der Stärkung des
                        Einzelnen zugutekommen.
                    </p>
                </div>
                <Reference>
                    <p>
                        1. ‘Abdu’l-Bahá, The Promulgation of Universal Peace
                        14:2
                    </p>
                    <p>2. zitiert in: Balyuzi, ‘Abdu’l-Bahá, Bd. 1, S. 385f.</p>
                </Reference>
            </Content>
            <Sidebar />
        </TwoColumns>
    </InnerPage>
);

export default DieGemeinschaft;

export const pageQuery = graphql`
    query {
        introImage: file(relativePath: { eq: "Kachel_Die-Gemeinde-3.jpg" }) {
            ...fluidImage
        }
        dieGemeinde: file(relativePath: { eq: "Gemeinde.m.png" }) {
            ...fluidImage
        }
    }
`;
